<template>
  <el-dialog
    title="新增操作员"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="40%"
    @close="handleClose"
  >
  <el-card style="margin:-20px 0">
    <el-form ref="form" :model="form" label-width="100px" style="padding: 0 40px">
      <el-form-item label="归属机构:">
        <el-select v-model="form.institutions" placeholder="请选择归属机构">
          <el-option :label="obj.companyName" :value="obj.companyId" v-for="obj in mechanismlist" :key="obj.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="操作员姓名:">
        <el-input v-model="form.username" placeholder="请输入操作员姓名" maxlength="10"></el-input>
      </el-form-item>
      <el-form-item label="操作员电话:">
        <el-input v-model="form.phone" placeholder="请输入操作员电话" @blur="testinput"></el-input>
      </el-form-item>
      <el-form-item label="角色:">
        <el-select v-model="form.role" placeholder="请选择操作员角色">
          <el-option :label="obj.roleName" :value="obj.id" v-for="obj in rolelist" :key="obj.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="机构权限:">
        <el-select v-model="form.power" placeholder="请选择机构权限" multiple>
        <el-option :label="obj.companyName" :value="obj.companyId" v-for="obj in mechanismlist" :key="obj.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户状态:">
        <el-select v-model="form.region" placeholder="请选择用户状态">
          <el-option label="启用" value="0"></el-option>
          <el-option label="禁用" value="1"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="roleadd">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      dialogVisible: true,
      form: {
        username: '',
        phone: '',
        institutions: '',
        power: '',
        region: '',
        role: '',
      },
      rolelist:[],
      mechanismlist:[],
      regular: '^1[3|4|5|6|7|8|9][0-9]{9}$'
    }
  },
  created() {
    // 查询所以有效角色
    postRequestAnthor('/computeruser/queryroles',{}).then(res => {
      this.rolelist=res
      console.log(res)
    })
    // 查询所有机构  
    postRequestAnthor('/company/queryCompany',{}).then(res => {
      this.mechanismlist=res
      console.log(res)
    })
  },
  methods: {
    // 添加
    roleadd() {
      console.log(
        this.form.username+"=>1",
        this.form.phone+"=>2",
        this.form.region+"=>3",
        this.form.institutions+"=>4",
        this.form.power+"=>5",
        this.form.role+"=>6",
      )
      if(this.form.username !='' && this.form.phone !='' && this.form.region !='' && this.form.institutions !='' && this.form.power !='' && this.form.role !=''){
          postRequestAnthor('/computeruser/adduser',{
        userName:this.form.username,
        phone:this.form.phone,
        status:this.form.region,
        companyId:this.form.institutions,
        createBy:1,
        companyAuthorityIds:this.form.power,
        roleId:[this.form.role]
      }).then(()=>{
        this.$message({
          message: '添加成功',
          type: 'success'
        });
        setTimeout(()=>{
          this.dialogVisible=false
        },1000)
      })
      }else{
        this.$message({
          message: '请完善新增操作员的所有信息',
          type: 'warning'
        });
      }
    },
    testinput(){
      if(!this.form.phone.match(this.regular)){
        this.$message({
          message: '请输入正确的手机号',
          type: 'warning'
        });
        this.form.phone=''
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.dialog-footer{
  text-align: center;
}
</style>
