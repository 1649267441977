<template>
  <div class="organ-management-page" v-loading="loading">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small" style="display:flex;justify-content: space-between;">
            <el-form-item label="操作员名称:">
              <el-input v-model="form.name" placeholder="请输入操作员名称" maxlength="10"></el-input>
            </el-form-item>
            <el-form-item label="操作员账号:">
              <el-input v-model="form.account" placeholder="请输入操作员账号" oninput = "value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="机构:">
              <el-select v-model="form.region" placeholder="请选择机构">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="obj.companyName"
                  :value="obj.companyId"
                  v-for="obj in mechanismlist"
                  :key="obj.companyId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="queryclick" type="primary" icon="el-icon-search" :disabled="disabledFalg">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="table-block" style="margin-top:19px">
      <div style="margin-bottom:20px;">
        <el-button @click="showDialog" type="primary" plain icon="el-icon-plus">新增</el-button>
        <el-button @click="handleDelete" plain icon="el-icon-delete"  type="danger">删除</el-button>
      </div>
      <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
    <!-- <div class="table-block"> -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}"
        :cell-style="{'text-align':'center'}"
        tooltip-effect="dark"
        style="width: 100%"
        height="440"
        @selection-change="handleSelectionChange"
        @row-click="handleClickTableRow"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="操作员姓名" prop="userName" width="120"> </el-table-column>
        <el-table-column prop="roleNames" label="角色" width="140"></el-table-column>
        <el-table-column prop="companyName" label="归属机构"> </el-table-column>
        <el-table-column prop="phone" label="操作员电话"> </el-table-column>
        <el-table-column prop="createTime" label="添加时间"> </el-table-column>
        <el-table-column prop="statusName" label="状态">
          <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#5479FF"
                inactive-color="#D5D5D5"
                active-value="0"
                @change="changeStatus(scope.row)"
                inactive-value="1">
              </el-switch>
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="modifyClick(scope.row)"  type="text" size="small">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
    <!-- 页码 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[8, 16, 24, 32]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background
    >
    </el-pagination>
    </el-card>
    <!-- 修改弹出框 -->
    <el-dialog title="修改操作员信息" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" v-loading="modifyloading">
      <el-card style="margin:-20px 0">
      <el-form ref="form" :model="form" label-width="100px" style="padding: 0 20px">
        <el-form-item label="归属机构:">
          <el-select v-model="modifylist.companyId" placeholder="请选归属机构" style="width: 100%">
            <el-option
              :label="objs.companyName"
              :value="objs.companyId"
              v-for="objs in mechanismlist"
              :key="objs.companyId + 'ccc'"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作员姓名:">
          <el-input v-model="modifylist.userName"></el-input>
        </el-form-item>
        <el-form-item label="操作员电话:">
          <el-input v-model="modifylist.phone"  @blur="testinput"></el-input>
        </el-form-item>
        <el-form-item label="角色:">
          <el-select v-model="roles" placeholder="请选择角色" style="width: 100%">
            <el-option :label="obj.roleName" :value="obj.id" v-for="obj in rolelist" :key="obj.id + 'aaa'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构权限:">
          <el-select v-model="powers" placeholder="请选择机构权限" style="width: 100%" multiple>
            <el-option
              :label="obj.companyName"
              :value="obj.companyId"
              v-for="obj in mechanismlist"
              :key="obj.companyId + 'cxx'"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户状态:">
          <el-select v-model="modifylist.status" placeholder="请选择用户状态" style="width: 100%">
            <el-option label="启用" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirclick">确 定</el-button>
      </span>
    </el-dialog>
    <addNewOperator v-if="isShow" @closeDialog="closeDialog"></addNewOperator>
  </div>
</template>

<script>
import addNewOperator from '@/views/basisSet/component/addNewOperator'
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      form: {
        name: '',
        region: [],
        username: '',
        power: '',
        role: '',
        account:''
      },
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      isShow: false,
      currentPage: 1,
      pagesize: 8,
      total: 0,
      modifylist: '',
      rowid: '',
      roles: '',
      powers: [],
      rolelist: '',
      mechanismlist: [],
      loading:true,
      regular: '^1[3|4|5|6|7|8|9][0-9]{9}$',
      modifyloading:false,
      disabledFalg:false
    }
  },
  components: { addNewOperator },
  created() {
    this.loaddata(this.pagesize, this.currentPage)
    // 查询所有机构
    postRequestAnthor('/company/queryCompany', {}).then(res => {
      // console.log(res);
      this.mechanismlist = res
    })
  },
  methods: {
    // 获取页面数据
    loaddata(pageSize, pageNo, userName, phone, companyIds) {
      postRequestAnthor('/computeruser/queryuserlist', { pageSize, pageNo, userName, phone, companyIds }).then(res => {
        console.log(res);
        this.total = res.count
        this.tableData = res.data
        this.loading=false    
      }).catch(()=>{
        this.loading=false
      })
    },
    // 启用禁用
    changeStatus(val){
      console.log(val);
      postRequestAnthor('/computeruser/update', {id:val.id,updateBy:1,status:val.status}).then(res=>{
        // console.log(res);
        if(res==true){
          this.$message({
          message: '修改成功',
          type: 'success'
        });
        }
      })
    },
    // 查询数据
    queryclick() {
      this.loading=true
      this.loaddata('8', '1', this.form.name, this.form.account, this.form.region != ''?[this.form.region]:null)
      this.disabledFalg = true
      setTimeout(()=>{
          this.disabledFalg = false
        },2000)
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val.map(item => {
        return item.id
      })
    },
    // 修改
    modifyClick(row) {
      this.modifyloading = true
      // console.log(this.form.region);
      // 查询所以有效角色
      this.rowid = row.id
      postRequestAnthor('/computeruser/queryroles', {}).then(res => {
        // console.log(res);
        this.rolelist = res
      })
      this.dialogVisible = true
      // 获取详情数据
      // console.log(this.rowid);
      postRequestAnthor('/computeruser/queryuserinfo', {
        id: this.rowid,
      }).then(res => {
        this.modifyloading = false
        this.loaddata(this.pagesize, this.currentPage)
        this.roles=res.roleList[0].id
        this.modifylist = res
        this.powers = res.companyList.map(items => {
          return items.companyId
        })
        // console.log(this.powers);
      })
    },
    testinput(){
      if(!this.modifylist.phone.match(this.regular)){
        this.$message({
          message: '请输入正确的手机号',
          type: 'warning'
        });
        this.modifylist.phone=''
      }
    },
    // 确认修改
    confirclick() {
// console.log(this.modifylist.userName,this.modifylist.phone,this.modifylist.status, this.modifylist.companyId,this.powers,this.roles);
      if(this.modifylist.userName != '' && this.modifylist.phone != '' && this.modifylist.status != '' && this.modifylist.companyId != '' && this.powers != "" && this.roles != ''){
        postRequestAnthor('/computeruser/update', {
        id: this.rowid,
        userName: this.modifylist.userName,
        phone: this.modifylist.phone,
        status: this.modifylist.status,
        companyId: this.modifylist.companyId,
        createBy: 1,
        companyAuthorityIds: this.powers,
        roleId: [this.roles],
        updateBy: 1,
      }).then(res => {
        if(res==true){
          this.loaddata(this.pagesize, this.currentPage)
          this.$message({
          message: '修改成功',
          type: 'success'
        });
          this.dialogVisible=false
        }
      })
      }else{
        this.$message({
          message: '完善操作员信息',
          type: 'warning'
        });
      }
      
    },

    handleClose() {
      this.dialogVisible = false
    },
    // 每页数据条数
    handleSizeChange(val) {
      this.loading = true
      this.pagesize = val
      this.loaddata(this.pagesize, this.currentPage)
    },
    // 当前页
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.loaddata(this.pagesize, this.currentPage)
    },
    // handleSelectionChange(val) {
    //   this.multipleSelection = val.map(item => item.id)
    // },
    closeDialog() {
      this.isShow = false
      this.loaddata(this.pagesize, this.currentPage)
    },
    showDialog() {
      this.isShow = true
    },
    
    // 删除数据
    handleDelete() {
      if(this.multipleSelection.length>0){
        this.$confirm('此操作将永久该操作员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          postRequestAnthor('/computeruser/deletes', {
            ids: this.multipleSelection,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.loaddata(this.pagesize, this.currentPage)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
      } 
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
.search > .el-form-item {
  margin-right: 13px !important;
}
</style>
